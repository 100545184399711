<template>
	<div class="card p-2 mb-0 item">
		<div class="body">
			<img
				:src="item.images ? item.images[0].thumb : '/rooster/not-item-img.png'"
				class="img-item"
				alt=""
			/>
			<b-button size="sm" variant="primary" @click="addItemToCart(item)">Agregar</b-button>

			<!-- <p class="measure bg-primary">{{ item.um.code }}</p> -->

			<p class="price bg-secondary">{{ item.currency.text }} {{ item.price }}</p>
		</div>

		<div class="footer">
			<p class="mb-50 mt-50">{{ item.name }}</p>

			<div class="container-category">
				<span class="badge badge-primary">
					{{ item.category.text }}
				</span>
			</div>
		</div>
	</div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
	name: "ItemsListOrdersItem",
	props: {
		item: {
			type: Object,
			default: () => {},
		},
	},
	methods: {
		...mapMutations("orders", ["ADD_ITEM_TO_CART"]),
		addItemToCart(item) {
			this.ADD_ITEM_TO_CART({ item });
		},
	},
};
</script>

<style lang="scss" scoped>
.item {
	box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1) !important;
	border: 1px solid #f5f5f5;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	// align-items: center;
	.img-item {
		width: 100%;
		height: 200px;
		object-fit: cover;
		background: #f5f5f5;
		border-radius: 8px;
		// padding: 1rem;
		box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1) !important;
	}
	.container-category {
		display: flex;
		align-items: center;
		margin-top: 0.4rem;
		span {
			font-size: 12px;
			margin-right: 0.4rem;
		}
	}

	.body {
		position: relative;
		.btn {
			position: absolute;
			top: 1rem;
			right: 1rem;
		}
		.measure {
			position: absolute;
			top: 1rem;
			left: 1rem;
			margin-bottom: 0;
			width: auto;
			color: white;
			padding: 0.4rem;
			border-radius: 50%;
			font-weight: bolder;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.price {
			position: absolute;
			bottom: 1rem;
			right: 1rem;
			width: auto;
			color: white;
			padding: 0.2rem 0.4rem;
			font-weight: bolder;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 0;
			border-radius: 8px;
		}
	}
	.footer {
		// background: red;
		width: 100%;
		margin-left: 0 !important;
	}
}
</style>
