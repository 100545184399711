import axios from "@/axios";
const url = "/api/purchase-order";

class OrderService {
	async createOrder({ season = new Date().getFullYear(), observation = null, products, status_id, date_request, date }) {
		try {
			const { data } = await axios.post(`${url}/store`, {
				season: season.toString().slice(2, 4),
				observation,
				products,
				status_id,
				date_request,
				date
			});
			return data;
		} catch (err) {
			return err.response.data;
		}
	}

	async updateOrder({ season = new Date().getFullYear(), observation, products, status_id, date_request, id, date }) {
		try {
			const { data } = await axios.put(`${url}/update-details/${id}`, {
				season: season.toString().slice(2, 4),
				observation,
				products,
				status_id,
				date_request,
				date
			});
			return data;
		} catch (err) {
			return err.response.data;
		}
	}

	async getOrder({ page, perpage, status_id = 1, search }) {
		// console.log('asd', season);
		try {
			const { data } = await axios.get(`${url}`, {
				params: { page, perpage, status_id, search },
			});
			return data;
		} catch (err) {
			return err.response.data;
		}
	}

	async getCounterOrder() {
		// console.log('asd', season);
		try {
			const { data } = await axios.get(`${url}/get-status-counters`);
			return data;
		} catch (err) {
			return err.response.data;
		}
	}
	async showOrderById({ id }) {
		try {
			const { data } = await axios.get(`${url}/show/${id}`);
			return data;
		} catch (err) {
			return err.response.data;
		}
	}

	async changeStatus({ status, observation, delivery_day, id, date_delivered, date_delivery, date }) {
		try {
			const { data } = await axios.put(`${url}/update/${id}`, {
				status,
				observation,
				delivery_day,
				date_delivered,
				date_delivery,
				date
			});
			return data;
		} catch (err) {
			return err.response.data;
		}
	}

	async getTrackingStatus({ id }) {
		try {
			const { data } = await axios.get(`${url}/get-tracking/${id}`);
			return data;
		} catch (err) {
			return err.response.data;
		}
	}

	async updatePriceDetails({ products }) {
		try {
			const { data } = await axios.put(`${url}/update-price`, {
				products,
			});
			return data;
		} catch (err) {
			return err.response.data;
		}
	}

	// async confirmOrder({ products }) {
	// 	try {
	// 		const { data } = await axios.put(`${url}/`, {
	// 			products,
	// 		});
	// 		return data;
	// 	} catch (err) {
	// 		return err.response.data;
	// 	}
	// }
}
export default new OrderService();
