<template>
	<div v-if="this.$store.state.app" class="container-create-order">
		<!-- <button class="btn btn-primary" @click="click123">Click</button> -->
		<div class="list-carrito">
			<ItemsListOrders />
			<CartOrders />
		</div>
	</div>
</template>

<script>
import ItemsListOrders from "./ItemsListOrders.vue";
import CartOrders from "./CartOrders.vue";

export default {
	name: "CreateOrder",
	components: {
		ItemsListOrders,
		CartOrders,
	},
	mounted() {
		this.isPreloading();
		// if(this.$store.state.app) {
		//     console.log('existe');
		// }
		// console.log('object', this.$store.state.app);
	},
	methods: {
		click123() {
			// this.isPreloading();
		},
	},
};
</script>

<style lang="scss" scoped>
.container-create-order {
	// background: red;
	.list-carrito {
		display: grid;
		grid-template-columns: 1fr !important;
		gap: 2rem;

		@media screen and (min-width: 900px) {
			grid-template-columns: 5fr 2fr !important;
		}
	}
}
</style>
