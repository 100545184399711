<template>
	<div class="container-accordion">
		<div class="d-flex" id="item-category" @click="setCategory">
			<p id="name">{{ ac.name }}</p>
			<p class="ml-1">{{ ac.level }}</p>
			<p class="ml-1">{{ ac.child && ac.child.length }}</p>
			<p class="ml-1">{{ac.id}}</p>
			<p class="ml-1">{{ ac.total_d }}</p>
			<p class="ml-1">{{ ac.description }}</p>
			<p class="ml-1">{{ ac.parent_id }}</p>
			<p class="ml-1">{{ ac.total_s }}</p>
			<!-- parent_id -->
			
		</div>
		<div v-if="ac.child" >
			<AccordionChild v-for="(it, index) in ac.child" :key="index" :ac="it"/>
		</div>
	</div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
	name: "Accordion",
	props: {
		ac: {
			type: Object,
		}
	},
	components: {
		AccordionChild: () => import("./Accordion.vue"),
	},
	
    methods: {
		...mapMutations('orders',['SET_CATEGORY_SELECT']),
        setCategory() {
			this.SET_CATEGORY_SELECT(this.ac)
            // console.log('category', this.ac.id);
			// this.$emit('setCategory', this.ac)
        }
    }
};
</script>


<style lang="scss">
.container-accordion{
	background: white !important;
	// height: 100px;
	// &:hover {
	// 	background: blueviolet !important;
	// }
}
</style>