<template>
	<div class="card mb-0 p-2">
		<b-row class="">
			<b-col cols="12" md="12" lg="4" class="d-flex flex-column align-items-start mt-3 justify-content-between">
				<span class="text-muted">
					Mostrando {{ start_page }} a {{ to_page }} de {{ total_data }} registros
				</span>
			</b-col>
			<!-- Pagination -->
			<b-col cols="12" md="12" lg="8" class="flex-nowrap">
				<div class="flex flex-wrap cross-center w-full gap-x-3 xt-search-input justify-content-end">
					<div class="xt-search-input__paginate">
						<b-pagination
							:value="jsonSearchItems.page"
							:total-rows="total_data"
							:per-page="jsonSearchItems.perpage"
							first-number
							last-number
							class=""
							prev-class="prev-item"
							next-class="next-item"
							@input="(e) => setPage(e)"
						>
							<template #prev-text>
								<feather-icon icon="ChevronLeftIcon" size="18" />
							</template>
							<template #next-text>
								<feather-icon icon="ChevronRightIcon" size="18" />
							</template>
						</b-pagination>
					</div>
					<div class="xt-search-input__search">
						<div class="search-container-value">
							<b-form-input
								type="search"
								class="search-input"
								:value="jsonSearchItems.search"
								@keyup.enter="init"
								placeholder="Buscar item"
								style="width: 200px"
								@input="(e) => writeSearch(e)"
							/>
							<div class="search-btn">
								<button @click="init" class="btn btn-primary w-75">Buscar</button>
								<FeatherIcon
									@click="viewSidebarFilters"
									icon="FilterIcon"
									size="20"
									class="w-25 text-primary cursor-pointer"
								/>
							</div>
						</div>
					</div>
				</div>
			</b-col>
		</b-row>

		<div class="container-items" v-if="items.length > 0">
			<ItemsListOrdersItem v-for="(item, index) in items" :key="index" :item="item" />
		</div>
		<div v-else>
			<p class="mb-0 text-center mt-2 font-small-4">SIN PRODUCTOS POR MOSTRAR</p>
		</div>

		<b-modal
			v-model="modalViewAddCategory.show"
			:title="modalViewAddCategory.title"
			hide-footer
			v-if="modalViewAddCategory.show"
		>
			<AddItemBrain @refresh="refresh" />
		</b-modal>

		<b-sidebar v-model="sidebarFilterItems.show" body-class="bg-white p-2" :title="sidebarFilterItems.title">
			<div class="d-flex flex-column align-items-center w-100">
				<b-form-group label="Categoria" class="mb-0 w-100">
					<!-- <v-select
						label="label"
						class="w-100"
						:value="jsonSearchItems.category_id"
						:options="arrayCategory"
						:clearable="false"
						:reduce="(option) => option.id"
						transition=""
						placeholder="Agregar categoria"
						@input="(e) => setCategory(e)"
					>
						<template slot="no-options"> Sin cintillo alas encontradas </template>

						<template slot="option" slot-scope="option">
							<div class="d-center">
								<p class="mb-0" :style="{ marginLeft: `${(option.level - 1) * 20}px` }">
									{{ option.label }}
								</p>
							</div>
						</template>
						<template slot="selected-option" slot-scope="option">
							<div class="selected d-center">
								<p class="mb-0">{{ option.label }}</p>
							</div>
						</template>
					</v-select> -->
					<!-- ASD -->
					<SelectCategoryItems v-model="jsonSearchItems.category_id" />
				</b-form-group>
				<div class="w-100 mt-2">
					<button class="btn btn-primary w-100" @click="init">Filtrar</button>
					<button class="btn btn-info w-100 mt-1" @click="resetFilters">Limpiar filtros</button>
				</div>
			</div>
		</b-sidebar>
		<AccordionSelectCategories />
	</div>
</template>

<script>
import mgtCategoryService from "@/services/mgt-category.service";
import { mapState, mapActions, mapMutations } from "vuex";
import ItemsListOrdersItem from "./ItemsListOrdersItem.vue";
import AccordionSelectCategories from "@/views/brain/others/views/categories/components/AccordionSelectCategories.vue";
import SelectCategoryItems from "@/views/brain/others/views/categories/components/SelectCategoryItems.vue";

export default {
	name: "ItemsListOrder",
	components: { ItemsListOrdersItem, AccordionSelectCategories, SelectCategoryItems },
	data() {
		return {
			modalViewAddCategory: {
				show: false,
				title: "Agregar item",
			},
			sidebarFilterItems: {
				show: false,
				title: "Filtrar item",
			},
		};
	},
	computed: {
		...mapState("orders", [
			"jsonSearchItems",
			"items",
			"start_page",
			"to_page",
			"total_data",
			"current_page",
			"arrayCategory",
		]),
	},
	async mounted() {
		setTimeout(async () => {
			await this.init();
		}, 100);
	},
	methods: {
		...mapMutations("orders", ["SET_SEARCH_ITEMS"]),
		...mapActions("orders", ["getNotifications"]),
		async init() {
			this.isPreloading();

			await this.getNotifications(this.jsonSearchItems);
			this.isPreloading(false);
		},
		writeSearch(e) {
			this.SET_SEARCH_ITEMS({
				key: "search",
				value: e,
			});
			this.jsonSearchItems.search.length == 0 && init();
		},
		async setPage(e) {
			this.SET_SEARCH_ITEMS({
				key: "page",
				value: e,
			});
			await this.init();
		},
		setCategory(e) {
			this.SET_SEARCH_ITEMS({
				key: "category_id",
				value: e,
			});
		},
		async resetFilters() {
			this.SET_SEARCH_ITEMS({
				key: "category_id",
				value: null,
			});
			this.SET_SEARCH_ITEMS({
				key: "page",
				value: 1,
			});
			this.SET_SEARCH_ITEMS({
				key: "search",
				value: null,
			});
			await this.init();
		},
		async initCategories() {
			const { data, message, status } = await mgtCategoryService.getMgtCategoryByModule({ module_id: 1 });
			this.categories = [...this.categories, ...data];
		},
		async viewSidebarFilters() {
			this.sidebarFilterItems.show = !this.sidebarFilterItems.show;
			// if (this.sidebarFilterItems.show && this.categories.length == 1) {
			// 	await this.initCategories();
			// }
		},
	},
};
</script>

<style lang="scss" scoped>
.container-items {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	gap: 2rem;
	margin-top: 2rem;
	@media screen and (min-width: 500px) {
		grid-template-columns: repeat(2, 1fr) !important;
	}
	@media screen and (min-width: 1000px) {
		grid-template-columns: repeat(3, 1fr) !important;
	}
	@media screen and (min-width: 1400px) {
		grid-template-columns: repeat(4, 1fr) !important;
	}
}
.xt-search-input__search {
	width: 100%;
	// margin: 1rem 0;
	display: flex;
	flex-direction: column;
	@media screen and (min-width: 1400px) {
		flex-direction: row;
		justify-content: flex-end;
	}
	.search-container-type {
		display: flex;
		h4 {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
	.search-container-value {
		display: flex;
		flex-direction: column;
		margin-top: 0.5rem;
		@media screen and (min-width: 700px) {
			flex-direction: row;
			// margin-top: .5rem;
			margin-left: 1rem;
		}
		.search-input {
			width: 100% !important;
			@media screen and (min-width: 700px) {
				width: 50% !important;
			}
			@media screen and (min-width: 1000px) {
				width: 50% !important;
			}
		}
		.search-btn {
			margin-top: 0.5rem;
			width: 100% !important;
			@media screen and (min-width: 700px) {
				margin-left: 0.5rem;
				margin-top: 0;
				width: 50% !important;
			}
		}
	}
}
</style>
