<template>
	<div class="container-select-categories d-none">
		<!-- <b-form-input v-model="text" placeholder="Enter your name"></b-form-input> -->
		<!-- <h3>Category: {{ category_select && category_select.id }}</h3> -->
		<button class="btn btn-primary" @click="click">Click</button>
		<Accordion v-for="(item, index) in categories" :index="index" :key="index" :ac="item" />
	</div>
</template>

<script>
import Accordion from "./Accordion.vue";
import { mapState, mapMutations } from "vuex";
import mgtCategoryService from "@/services/mgt-category.service";

export default {
	name: "AccordionSelectCategories",
	components: {
		Accordion,
	},
	props: ["dateIn", "dateFn"],
	data() {
		return {
			category: null,
			text: null,
			categories: [],
		};
	},
	computed: {
		...mapState("orders", ["reloadCategories"]),
	},
	async mounted() {
		await this.init();
		setTimeout(() => {
			this.click();
		}, 300);
	},

	methods: {
		...mapMutations("orders", ["SET_ARRAY_CATEGORY", "RELOAD_CATEGORIES"]),
		async init() {
			this.isPreloading();
			// Array.from({ length: Math.max(...this.categories.map((it) => it.level)) }).forEach((it, index) => {
			// 	this.levels.push(index + 1);
			// });
			const { data, message, status } = await mgtCategoryService.getMgtCategoryByModule({
				module_id: 1,
				start_date: this.dateIn,
				end_date: this.dateFn,
			});

			this.categories = data.map((it) => ({ ...it, show: false }));
			// this.isPreloading(false);
		},
		click() {
			let data = [];
			const resp = document.querySelectorAll("#item-category");
			resp.forEach((it) => {
				data.push({
					id: parseInt(it.children[3].innerHTML),
					level: it.children[1].innerHTML,
					label: it.children[0].innerHTML,
					total_d: it.children[4].innerHTML,
					description: it.children[5].innerHTML,
					parent_id: it.children[6].innerHTML == "" ? null : parseInt(it.children[6].innerHTML),
					total_s: it.children[7].innerHTML,
				});
			});
			this.SET_ARRAY_CATEGORY(data);
			this.isPreloading(false);
		},
	},
	watch: {
		async reloadCategories() {
			if (this.reloadCategories) {
				await this.init();
				setTimeout(() => {
					this.click();
					this.RELOAD_CATEGORIES(false);
				}, 300);
			}
		},
	},
};
</script>

<style lang="scss">
.container-select-categories {
	width: 100%;
}
</style>
