<template>
	<div class="">
		<div class="card p-2">
			<div class="code mb-1" v-if="code_order">
				<h4>Actualizar order: {{ code_order }}</h4>
			</div>
			<div class="header">
				<div class="container-total-items">
					<FeatherIcon icon="ShoppingCartIcon" size="24" class="text-primary cursor-pointer" />
					<p v-if="sumTotalItems(cart)" class="number mb-0 bg-danger">
						{{ sumTotalItems(cart) }}
					</p>
				</div>
				<FeatherIcon
					@click="REMOVE_TOTAL_ITEMS_TO_CART"
					icon="Trash2Icon"
					size="24"
					class="text-primary cursor-pointer"
				/>
			</div>
			<div class="container-list-items">
				<!-- {{cart.length}} -->
				<template v-if="cart.length > 0">
					<div v-for="(item, index) in cart" :key="index" class="item">
						<img
							:src="item.images ? item.images[0].thumb : '/rooster/not-item-img.png'"
							onerror="this.src='/rooster/not-item-img.png'"
							class="img-item"
							alt=""
						/>
						<div class="w-100 p-25 container-body">
							<p class="mb-0">{{ item.name }}</p>
							<div class="mt-50 d-flex align-items-center justify-content-between">
								<div class="w-100">
									<feather-icon
										@click="DECREMENT_ITEM_TO_CART({ item })"
										icon="MinusCircleIcon"
										size="30"
										class="cursor-pointer"
										:class="{ 'pointer-events-none': item.counter < 1 }"
									/>
									<b-avatar
										variant="primary"
										style="font-size: 40px !important"
										class="mx-1"
										size="30"
										button
										><span class="font-small-4">{{ item.counter }}</span></b-avatar
									>
									<feather-icon
										@click="ADD_ITEM_TO_CART({ item })"
										icon="PlusCircleIcon"
										size="30"
										class="cursor-pointer"
									/>
								</div>

								<money
									v-bind="money"
									:value="item.price"
									class="form-control"
									placeholder="Agregar precio"
									:style="{ width: '90px', textAlign: 'center' }"
									@input="(e) => SET_PRICE_CART({ key: index, value: e })"
								></money>

								<p class="mb-0 font-weight-bolder d-flex ml-1" :style="{ width: '180px' }">
									Total: {{ item.currency.code }}{{ (item.price * item.counter).toFixed(2) }}
								</p>
							</div>
							<feather-icon
								@click="REMOVE_ITEM_TO_ARRAY({ id: item.id })"
								icon="XIcon"
								size="18"
								class="icon-delete cursor-pointer text-danger"
							/>
							<!-- REMOVE_ITEM_TO_ARRAY -->
						</div>
					</div>
				</template>
				<div v-else class="shadow">
					<p class="mb-0 p-1 text-center font-weight-bolder">SIN PRODUCTOS</p>
				</div>
			</div>
			<div class="mt-1">
				<b-form-group label="Observacion">
					<b-form-textarea
						id="textarea"
						v-model="observation"
						placeholder="Agregar observacion (opcional)."
						rows="3"
						max-rows="6"
						:disabled="cart.length == 0"
					></b-form-textarea>
				</b-form-group>
			</div>
			<div class="mt-1">
				<b-form-datepicker
					placeholder="Fecha solicitada"
					:date-format-options="{
						year: 'numeric',
						month: 'numeric',
						day: 'numeric',
					}"
					locale="es"
					v-model="date_request"
					v-b-tooltip.hover
					title="Fecha solicitada"
					class="mr-1 w-100"
					:disabled="cart.length == 0"
				/>
			</div>
			<div class="total mt-1 d-flex" v-if="cart.length > 0">
				<b-button
					class="w-100 font-weight-bolder mr-25"
					variant="success"
					@click="order_id ? updateOrder(6) : createOrder(6)"
					:disabled="!isSupervisor"
					>Guardado<FeatherIcon icon="SaveIcon" class="text-white cursor-pointer ml-25" />
				</b-button>
				<b-button
					class="w-100 font-weight-bolder ml-25"
					variant="primary"
					@click="order_id ? updateOrder(1) : createOrder(1)"
					:disabled="!isSupervisor"
					>Solicitar<FeatherIcon icon="PackageIcon" class="text-white cursor-pointer ml-25" />
				</b-button>
				<!-- {{ sumTotalPrice(cart).toFixed(2) }} -->
				<!-- guardado 6
				solicitado 1 -->
			</div>
		</div>

		<!-- <b-modal
            v-model="viewModalOrderCreate.show"
            :title="viewModalOrderCreate.title"
            v-if="viewModalOrderCreate.show"
        >
            <VerifyInputOrder :items="viewModalOrderCreate.data" />
        </b-modal> -->
	</div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import orderService from "@/services/order.service";
import VerifyInputOrder from "./VerifyInputOrder.vue";
import { Money } from "v-money";
import moment from "moment";

export default {
	name: "CartOrders",
	components: {
		Money,
		VerifyInputOrder,
	},
	data() {
		return {
			viewModalOrderCreate: {
				show: false,
				title: "Crear orden de compra",
				data: [],
			},
			money: {
				decimal: ",",
				thousands: ".",
				prefix: "",
				suffix: "",
				precision: 2,
				masked: false,
			},
			observation: null,
			date_request: new Date(),
			code_order: null,
		};
	},
	computed: {
		...mapState("orders", ["cart", "jsonSearchItems"]),
		sumTotalItems: () => (items) => {
			const data = items.map((it) => it.counter);
			return data.length > 0 ? data.reduce((a, b) => a + b) : 0;
		},
		sumTotalPrice: () => (items) => {
			const data = items.map((it) => it.counter * it.price);
			return data.length > 0 ? data.reduce((a, b) => a + b) : 0;
		},
		order_id() {
			return this.$route.query.order_id;
		},
		countItems() {
			return this.cart.length;
		},
	},
	mounted() {
		this.initCartByORder();
		this.INIT_LOCAL_STORAGE();
	},
	destroyed() {
		this.REMOVE_TOTAL_ITEMS_TO_CART();
	},
	methods: {
		...mapMutations("orders", [
			"INIT_LOCAL_STORAGE",
			"REFRESH_LOCAL_STORAGE",
			"ADD_ITEM_TO_CART",
			"DECREMENT_ITEM_TO_CART",
			"REMOVE_TOTAL_ITEMS_TO_CART",
			"REMOVE_ITEM_TO_ARRAY",
			"SET_PRICE_CART",
		]),
		...mapActions("orders", ["getNotifications"]),
		openModalOrder() {
			this.viewModalOrderCreate.show = true;
			this.viewModalOrderCreate.data = this.cart;
		},
		async createOrder(status_id) {
			const { isConfirmed } = await this.showConfirmSwal();
			if (!isConfirmed) return;
			this.isPreloading();
			const items = this.cart.map((it) => ({
				id: it.id,
				price: it.price,
				currency_id: it.currency.id,
				quantity: it.counter,
				um_id: it.um.id,
			}));
			const resp = await orderService.createOrder({
				products: items,
				observation: this.observation,
				date_request: moment(this.date_request).format("YYYY-MM-DD"),
				status_id,
				date: moment(this.date_request).format("YYYY-MM-DD"),
			});

			this.REMOVE_TOTAL_ITEMS_TO_CART();
			await this.getNotifications(this.jsonSearchItems);
			this.isPreloading(false);

			this.$router.push({ name: "orders-list-sr" });
		},
		async updateOrder(status_id) {
			const { isConfirmed } = await this.showConfirmSwal();
			if (!isConfirmed) return;
			this.isPreloading();
			const items = this.cart.map((it) => ({
				id: it.id,
				price: it.price,
				currency_id: it.currency.id,
				quantity: it.counter,
				um_id: it.um.id,
			}));
			const resp = await orderService.updateOrder({
				products: items,
				observation: this.observation,
				date_request: moment(this.date_request).format("YYYY-MM-DD"),
				status_id,
				id: this.order_id,
				date: moment(this.date_request).format("YYYY-MM-DD"),
			});

			this.REMOVE_TOTAL_ITEMS_TO_CART();
			await this.getNotifications(this.jsonSearchItems);
			this.isPreloading(false);

			this.$router.push({ name: "orders-list-sr" });
		},
		async initCartByORder() {
			if (this.$route.query.order_id) {
				const { data } = await orderService.showOrderById({ id: this.$route.query.order_id });

				if (data[0].status_id != 6) {
					this.$router.push({
						name: `orders-cart-sr`,
						query: {},
					});
					this.REMOVE_TOTAL_ITEMS_TO_CART();
					this.observation = null;
					this.date_request = null;
					return;
				}

				data.forEach((it) => {
					this.ADD_ITEM_TO_CART({
						item: {
							category: {
								id: null,
								text: it.category,
							},
							quantity: it.quantity,
							currency: {
								code: it.currency,
								id: it.currency_id,
								text: it.code_currency,
							},
							description: it.description,
							id: it.item_id,
							images: it.images,
							name: it.name,
							price: parseFloat(it.price_d) > 0 ? parseFloat(it.price_d) : parseFloat(it.price_s),
							um: {
								code: it.um_code,
								id: it.um_id,
								text: it.um,
							},
						},
					});
					return;
				});
				this.observation = data[0].observation;
				this.date_request = data[0].date_request;
				this.code_order = data[0].code;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	// margin: 1rem 0;
	margin-bottom: 1rem;
}
.container-list-items {
	.item {
		display: flex;
		align-items: center;
		width: 100% !important;
		margin-bottom: 1rem;
		box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1) !important;
		img {
			width: 60px;
			height: 60px;
			object-fit: cover;
			background: #f5f5f5;
			border-radius: 8px;
			padding: 0.2rem;
			box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
			margin-right: 0.5rem;
		}
		position: relative;
		.container-body {
			.icon-delete {
				position: absolute;
				top: 5px;
				left: -3px;
				border: 1px solid red;
				background: white;
				border-radius: 50%;
				padding: 0.1rem;
			}
		}
	}
}
.container-total-items {
	position: relative;
	.number {
		position: absolute;
		top: -8px;
		left: 16px;
		width: 20px;
		height: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: white;
		border-radius: 50%;
		font-weight: bolder;
		font-size: 12px;
	}
}
</style>
