<template>
    <div>
        <div>
            <div class="container-list-items">
				<div v-for="(item, index) in items" :key="index" class="item">
					<img :src="item.image" onerror="this.src='/rooster/not-item-img.png'" class="img-item" alt="" />
					<div class="w-100 p-25">
						<p class="mb-0">{{ item.title }}</p>
						<div class="mt-50 d-flex align-items-center justify-content-between">
							<div class="w-100">
								<feather-icon
									@click="item.counter--"
									icon="MinusCircleIcon"
									size="30"
									class="cursor-pointer"
									:class="{ 'pointer-events-none': item.counter < 1 }"
								/>
								<b-avatar
									variant="primary"
									style="font-size: 40px !important"
									class="mx-1"
									size="30"
									button
									><span class="font-small-4">{{ item.counter }}</span></b-avatar
								>
								<feather-icon
									@click="item.counter++"
									icon="PlusCircleIcon"
									size="30"
									class="cursor-pointer"
								/>
							</div>
							<p class="mb-0 font-weight-bolder mr-1">{{ item.price * item.counter }}</p>
						</div>
					</div>
				</div>
			</div>
        </div>
    </div>
</template>

<script >
export default {
    name: 'VerifyInputOrder',
    props: {
        items: {
            type: Array,
            default: []
        }
    },
    mounted() {
        console.log('items', this.items);
    }
}
</script>

<style lang="scss">
.container-list-items {
	.item {
		display: flex;
		align-items: center;
		width: 100% !important;
		margin-bottom: 1rem;
		box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1) !important;
		img {
			width: 60px;
			height: 60px;
			object-fit: cover;
			background: #f5f5f5;
			border-radius: 8px;
			padding: 0.2rem;
			box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
			margin-right: 0.5rem;
		}
	}
}
</style>